@import 'src/scss/bootstrap_base';

// Restores print styles for bs5.
@import 'node_modules/bootstrap-print-css/css/bootstrap-print.css';

@media print {
    app-filter, .sidebar, .nav-tabs, app-navbar{
        display: none!important;
    }

    .content-container {
        padding: 0!important;
        box-shadow: none!important;
    }

    .content-fluid {
        padding-top: 0!important;
        padding-bottom: 0 !important;
    }

    .app-wrapper {
        margin-top: 0!important;
        padding-top: 0!important;
    }


}

