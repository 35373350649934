@import 'src/scss/bootstrap_base';

.btn-primary {
    @include button-variant($primary, $primary, #FFF, darken($primary,10%), darken($primary, 10%), #FFF);
}

.btn-outline-primary {
    @include button-outline-variant($primary, #FFF);
}

.btn-danger {
    @include button-variant($danger, $danger, #FFF, darken($danger,10%), darken($danger, 10%), #FFF);
}

.btn-outline-danger {
    @include button-outline-variant($danger, #FFF);
}

.btn-success {
    @include button-variant($success, $success, #FFF, darken($success,10%), darken($success, 10%), #FFF);
}

.btn-outline-success {
    @include button-outline-variant($success, #FFF);
}

.btn-gray {
    @include button-variant(#929292, #929292, #FFF, darken(#929292,10%), darken(#929292, 10%), #FFF);
}

.btn-outline-gray {
    @include button-outline-variant(#929292, #FFF, #929292, #929292, #fff);
}

.page-link, .disabled > .page-link {
  background-color: $content-bg;
}

.dropdown-menu {
  --bs-dropdown-bg: #fff;
}

.table {
  --bs-table-bg: --bs-white;
}

.hidden {
  position: absolute;
  visibility: hidden;
}

h1 {
  font-weight: 200;
}

.toast-container {
  .ngx-toastr {
    width: 450px !important;
    max-width: calc(100vw - 25px) !important;
  }

  &.toast-top-center {
    padding-top: 5px;
  }
}

@media (max-width: 1599.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.page-link {
  line-height: 1.5;
  padding: 0.3rem 0.75rem;
  font-size: 0.95rem;
}

.modal { -webkit-overflow-scrolling: touch; }

.modal-dialog {
  &.modal-xxl, &.modal-xl, &.modal-lg {
    @include media-breakpoint-between(sm, xl) {
      max-width: calc(100vw - 40px) !important;
    }
  }

  @include media-breakpoint-up(lg) {
    &.modal-xxl {
      max-width: calc(100vw - 40px);
      width: 1600px;
    }
  }

  .container {
    @include media-breakpoint-down(lg) {
      max-width: unset !important;
    }
  }

  .modal-body {
    padding: 0.3rem;

    @include media-breakpoint-up(md) {
      padding: 1rem;
    }
  }

}
.modal-open {
  .modal {
    overflow-y: auto;
  }
}

.modal-backdrop {
  background-color: rgba(#000, 0);
  opacity: 1 !important;
  transition: background-color linear 150ms;
}
.modal-backdrop.show {
  opacity: 1!important;
  background-color: rgba(#000, 0.6);
  @include media-breakpoint-up(lg) {
    backdrop-filter: blur(5px);
  }
}

.row {
  clear: both;
}

h1, h2, h3, h4, h5, h6 {
  .btn {
    margin-bottom: 0.5em;
  }
}

h1,h2{
  margin: 1.5em 0 1em;
}

i, p {
  @include media-breakpoint-down(md) {
    font-size: 90%;
  }
}
.modal-lg, .modal-xl {
  @include media-breakpoint-between(md, xl) {
    max-width: 750px;
  }
}

.nav-tabs {
  .nav-item {
    .nav-link {
      color: $text-muted;
      border-top-width: 2px;
      border-radius: 5px 5px 0 0;
      &:hover {
        background-color: rgba($content-bg, 0.6);
        //color: rgba($body-color, 0.8);
      }
      &.active {
        color: $body-color;
        background-color: $content-bg;
        border-bottom-color: $content-bg;
        border-top-color: $primary!important;
      }
    }
  }
}

.dropdown-menu {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  .dropdown-item {
    @include media-breakpoint-down(lg) {
      padding: 0.6rem 1.5rem;
    }

    &.danger {
      color: $danger;

      &:hover {
        background-color: $danger;
        color: #FFF;
      }
    }

    &.warning {
      color: $warning;

      &:hover {
        background-color: $warning;
        color: #FFF;
      }
    }
  }
}

//
// CUSTOM DATE DROPDOWNS
//
.custom-calendar.dropdown-menu {
  padding: 0;

  ngb-datepicker {
    border: none;
    width: 100%;
    min-width: 300px;
    max-width: 480px;
    left: unset !important;
    right: 0 !important;

    .ngb-dp-month {
      width: 100%;
    }

    .ngb-dp-today > div{
      border: 1px solid $input-border-color;
    }

    .ngb-dp-day, .ngb-dp-week-number, .ngb-dp-weekday {
      width: 14.2857%;

      & > div {
        width: 100%;
      }
    }
  }

  .custom-select {
    padding: 0.5rem !important;
    height: auto !important;
    margin: 0 3px
  }

  .inner {
    position: relative;
    .top{
      font-size: $input-font-size;
      border-bottom: $border-color 1px solid;
    }
    .actions {
      border-top: $border-color 1px solid;
    }
  }

  @include media-breakpoint-down(lg) {
    z-index: 100025!important;
    position: fixed!important;
    left: 0!important;
    right: 0!important;
    top: 0!important;
    bottom: 0!important;
    margin-left: 0!important;
    margin-right: 0!important;
    transform: none!important;
    background-color: transparent!important;
    box-shadow: none!important;

    &::before {
      content: ' ';
      position: fixed;
      pointer-events: none;
      height: 100vh;
      width: 100vw;
      z-index: 0;
      background-color: rgba(0, 0, 0, 0.75);
      top: 0;
      left: 0;
    }

    .inner {
      background-color: $dropdown-bg;
      position: absolute!important;
      top: 70px!important;
      left: 0!important;
      right: 0!important;
      margin: 0 auto!important;
      width: calc(100vw - 20px);
      max-width: 480px;
      z-index: 1;
      max-height: calc(100vh - 80px);
      overflow-y: auto;

      .actions {
        position: sticky;
        bottom: 0;
        background-color: inherit;
        z-index: 4;
      }
    }
  }
}
